import fetch from '../util/fetch'
import { backendUrl } from '../config'

export const register = ({
  userName,
  email,
  password,
  firstName,
  lastName,
  userType = 'CLIENT',
}) =>
  fetch({
    url: `${backendUrl}/register?userType=${userType}`,
    method: 'POST',
    data: {
      userName,
      email,
      password,
      firstName,
      lastName,
    },
  })
