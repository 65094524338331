import { useQuery } from 'react-query'
import { getUser } from '../api'

export const useGetUser = ({ userName }) => {
  const { data, isLoading, isRefetching, isError, isSuccess } = useQuery(
    'get-user',
    () => getUser({ userName }),
    { cacheTime: 0 },
  )
  return { isLoading, isRefetching, data, isError, isSuccess }
}
