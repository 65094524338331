import Table from 'react-bootstrap/Table'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import { showModal, closeModal, useModal } from '../hooks'
import { Register } from './Register'
import { UpdateUser } from './UpdateUser'
import { DeleteUser } from './DeleteUser'

export const AllClientsTable = ({ clients }) => {
  const [, dispatch] = useModal()
  const onCreateUser = () => {
    showModal(
      dispatch,
      <Register isModal onClose={() => closeModal(dispatch)} />,
    )
  }
  return (
    <div>
      <Stack direction="horizontal" gap={3}>
        <h2>Active clients</h2>
        <div className="ms-auto">
          <Button variant="success" onClick={onCreateUser}>
            Create client
          </Button>
        </div>
      </Stack>
      <Table striped>
        <thead>
          <tr>
            <th>User name</th>
            <th>First name</th>
            <th>Last name</th>
            <th>E-mail</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            const onUpdateUser = () => {
              showModal(
                dispatch,
                <UpdateUser
                  isModal
                  onClose={() => closeModal(dispatch)}
                  userName={client.userName}
                />,
              )
            }
            const onDeleteUser = () => {
              showModal(
                dispatch,
                <DeleteUser
                  isModal
                  onClose={() => closeModal(dispatch)}
                  userName={client.userName}
                />,
              )
            }
            return (
              <tr key={client.userName}>
                <td>{client.userName}</td>
                <td>{client.firstName}</td>
                <td>{client.lastName}</td>
                <td>{client.email}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={onDeleteUser}
                    className="margin-right"
                  >
                    Delete
                  </Button>
                  <Button variant="warning" onClick={onUpdateUser}>
                    Update
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
