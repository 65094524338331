import Form from 'react-bootstrap/Form'
import { Controller } from 'react-hook-form'
import './InputField.css'

export const TextField = ({ label, name, control, type, error, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <Form.Group className="mb-3">
          <Form.Label>
            {label}
            <span className="requiredTick">*</span>
          </Form.Label>
          <Form.Control
            disabled={disabled}
            type={type}
            {...field}
          ></Form.Control>
          <Form.Text className="formTextRed" variant="error">
            {error}
          </Form.Text>
        </Form.Group>
      )}
    />
  )
}
