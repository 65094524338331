import { Card, Alert } from 'react-bootstrap'
import { FormErrorContextProvider, useFormError } from '../hooks/useFormError'

const FormWrapperWrappedInErrorProvider = ({
  children,
  title,
  subTitle,
  titleIsLarge,
}) => {
  const [error] = useFormError()
  return (
    <Card>
      <Card.Body>
        {titleIsLarge ? <h1>{title}</h1> : <Card.Title>{title}</Card.Title>}
        {subTitle && <Card.Subtitle>{subTitle}</Card.Subtitle>}
        <hr />
        {error && (
          <Alert className="mt-3" variant="danger">
            {error}
          </Alert>
        )}
        {children}
      </Card.Body>
    </Card>
  )
}

export const FormWrapper = ({ children, title, subTitle, titleIsLarge }) => (
  <FormErrorContextProvider>
    <FormWrapperWrappedInErrorProvider
      title={title}
      subTitle={subTitle}
      titleIsLarge={titleIsLarge}
    >
      {children}
    </FormWrapperWrappedInErrorProvider>
  </FormErrorContextProvider>
)
