import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { TextField } from './TextField'
import { CheckField } from './CheckField'
import { RadioField } from './RadioField'
import Card from 'react-bootstrap/Card'
import { useTFAToken, useGetUser, useSubmitTFAFormMutation } from '../hooks'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

const schema = yup.object({
  acknowledgement: yup
    .boolean()
    .oneOf([true], 'Please acknowledge before proceeding'),
  caseManagersFullName: yup
    .string()
    .required('Please enter your case managers full name'),
  clientsFullName: yup.string().required('Please enter clients full name'),
  caseManagersEmail: yup.string().required('Please enter case managers e-mail'),
  deviceType: yup.string().required('Please enter the device type'),
  q1: yup.string().required('Please answer the question above'),
  q2: yup.string().required('Please answer the question above'),
  q3: yup.string().required('Please answer the question above'),
  q4: yup.string().required('Please answer the question above'),
  q5: yup.string().required('Please answer the question above'),
  q6: yup.string().required('Please answer the question above'),
  q7: yup.string().required('Please answer the question above'),
  q8: yup.string().required('Please answer the question above'),
  q9: yup.string().required('Please answer the question above'),
  q10: yup.string().required('Please answer the question above'),
  q11: yup.string().required('Please answer the question above'),
  q12: yup.string().required('Please answer the question above'),
})

export const TFAForm = () => {
  const [userIdentifiers] = useTFAToken()
  const { isLoading, isSuccess, data } = useGetUser({
    userName: userIdentifiers.userName,
  })

  if (isLoading) {
    return (
      <div className="center-div">
        <Spinner animation="border" />
      </div>
    )
  }

  if (isSuccess) {
    const { user } = data
    return <TFAFormLoaded userData={user} />
  }

  return <Alert variant="warning">Error fetching user details</Alert>
}

const TFAFormLoaded = ({ userData }) => {
  const { firstName, lastName, email } = userData
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      acknowledgement: false,
      caseManagersFullName: `${firstName} ${lastName}`,
      clientsFullName: '',
      caseManagersEmail: email,
      deviceType: '',
    },
  })
  const { onSubmit, isLoading } = useSubmitTFAFormMutation()

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card.Title>Personal details</Card.Title>
      <TextField
        name="caseManagersFullName"
        label="Case Manager Name"
        type="text"
        control={control}
        error={errors.caseManagersFullName?.message}
      />
      <TextField
        name="clientsFullName"
        label="Clients Full Name"
        type="text"
        control={control}
        error={errors.clientsFullName?.message}
      />
      <TextField
        name="caseManagersEmail"
        label="Case Manager Email"
        type="email"
        control={control}
        error={errors.caseManagersEmail?.message}
      />
      <TextField
        name="deviceType"
        label="Device Type"
        type="text"
        control={control}
        error={errors.deviceType?.message}
      />
      <CheckField
        name="acknowledgement"
        label={
          <p>
            <strong>IMPORTANT:</strong> This form is a guide only, and is
            general in its advice. This form is a guide to indicate possible
            risk but{' '}
            <em>
              none of the authors, contributors, administrators, or anyone else
              connected with the form, in any way whatsoever, are responsible
              for the device's if compromised or not.
            </em>
          </p>
        }
        type="checkbox"
        control={control}
        error={errors.acknowledgement?.message}
      />
      <hr />
      <Card.Title>Questionnaire</Card.Title>
      {Object.keys(questions).map((key) => {
        return (
          <RadioField
            key={key}
            name={key}
            label={questions[key]}
            control={control}
            error={errors[key]?.message}
          />
        )
      })}
      <Button type="submit" variant="primary">
        {isLoading && (
          <Spinner as="span" animation="border" size="sm" role="status" />
        )}
        Submit
      </Button>
    </Form>
  )
}

const questions = {
  q1: 'Has the PUV (Person using violence) had physical access to the device?',
  q2: 'Have you reset the password to your Apple ID or Account ID to your device?',
  q3: 'Is your device over 4 years old?',
  q4: 'Does your device lose battery faster than normal?',
  q5: 'Does your device overheat or get hot?',
  q6: 'Does your device lag or is slow to open applications?',
  q7: "Is your device's telecommunications provider bill showing higher than average downloads?",
  q8: 'Have you found applications in your device you did not download?',
  q9: 'Is your device updated to the latest software version?',
  q10: 'Does your device show calls or messages in its log that you did not send or call?',
  q11: 'Do you have two factor authentication enabled on your device?',
  q12: 'Have you received notifications stating a login to your accounts has occurred which was not you?',
}
