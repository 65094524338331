import { useMutation } from 'react-query'
import { register } from '../api'
import { useModal, closeModal } from './useModal'
import { useFormError, showError } from './useFormError'

export const useRegisterMutation = ({ isModal }) => {
  const [, dispatch] = useModal()
  const [, setError] = useFormError()
  const { mutate, isLoading } = useMutation(
    ({ userName, password, email, firstName, lastName }) =>
      register({ userName, password, email, firstName, lastName }),
    {
      onSuccess: () => {
        if (isModal) closeModal(dispatch)
      },
      onError: (err) => {
        const response = err.response
        showError(
          setError,
          response?.data?.error?.message || err.message || 'Unknown error',
        )
      },
    },
  )
  return { isLoading, onSubmit: mutate }
}
