import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import { FormWrapper } from './FormWrapper'
import { useDeleteUserMutation } from '../hooks'
import Alert from 'react-bootstrap/Alert'

const DeleteUserForm = ({ userName, isModal, onClose }) => {
  const { onSubmit, isLoading } = useDeleteUserMutation({ isModal })

  return (
    <>
      <Card.Body>
        <Alert variant="danger">This is not reversible are you sure?</Alert>
      </Card.Body>
      <hr />
      <div className="right-align">
        {isModal && (
          <Button variant="danger" onClick={onClose} className="margin-right">
            Close
          </Button>
        )}
        <Button variant="primary" onClick={() => onSubmit({ userName })}>
          {isLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" />
          )}
          Delete
        </Button>
      </div>
    </>
  )
}

export const DeleteUser = ({ userName, isModal, onClose }) => (
  <FormWrapper title="Delete user">
    <DeleteUserForm userName={userName} isModal={isModal} onClose={onClose} />
  </FormWrapper>
)
