import { useRoutes } from 'react-router-dom'
import { guestRoutes, clientRoutes, adminRoutes } from './routes'
import { useTFAToken, useModal } from './hooks'
import { NavBar } from './components/NavBar'
import Modal from 'react-bootstrap/Modal'

function App() {
  const [token] = useTFAToken()
  const [{ showModal, modal }] = useModal()

  const Routes = () =>
    useRoutes(
      token
        ? token.userType === 'ADMIN'
          ? adminRoutes
          : clientRoutes
        : guestRoutes,
    )
  return (
    <div>
      <NavBar />
      <Modal show={showModal}>
        <Modal.Body>{modal}</Modal.Body>
      </Modal>
      <div className="center">
        <Routes />
      </div>
    </div>
  )
}

export default App
