import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import { useTFAToken, useModal, showModal, closeModal } from '../hooks'
import { useNavigate } from 'react-router-dom'
import { UpdateUser } from './UpdateUser'

export const NavBar = () => {
  const navigate = useNavigate()
  const [, dispatch] = useModal()
  const [tfaToken, setTFAToken] = useTFAToken()
  const onUpdateUser = () => {
    const { userName } = tfaToken
    showModal(
      dispatch,
      <UpdateUser
        isModal
        onClose={() => closeModal(dispatch)}
        userName={userName}
      />,
    )
  }
  const logIn = () => navigate('/login')
  const logOut = () => {
    setTFAToken(null)
    navigate('/login')
  }
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="home">TFA</Navbar.Brand>
        <Nav className="justify-content-end">
          {tfaToken && tfaToken.userType === 'ADMIN' && (
            <Button
              variant="info"
              className="margin-right"
              onClick={onUpdateUser}
            >
              Update profile
            </Button>
          )}
          <Button onClick={tfaToken ? logOut : logIn}>
            {tfaToken ? 'Log out' : 'Log in'}
          </Button>
        </Nav>
      </Container>
    </Navbar>
  )
}
