import Form from 'react-bootstrap/Form'
import { Controller } from 'react-hook-form'
import './InputField.css'

export const RadioField = ({ label, name, control, error }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Form.Group className="mb-3">
          <Form.Label>
            {label}
            <span className="requiredTick">*</span>
          </Form.Label>
          <Form.Group>
            <Form.Check
              type="radio"
              label="Yes"
              inline
              value="yes"
              checked={value === 'yes'}
              onChange={onChange}
            />
            <Form.Check
              type="radio"
              label="No"
              inline
              value="no"
              checked={value === 'no'}
              onChange={onChange}
            />
            <Form.Check
              type="radio"
              label="Maybe"
              inline
              value="maybe"
              checked={value === 'maybe'}
              onChange={onChange}
            />
          </Form.Group>
          <Form.Text className="formTextRed" variant="error">
            {error}
          </Form.Text>
        </Form.Group>
      )}
    />
  )
}
