import { useReducer, useContext, createContext } from 'react'

const modalReducer = (state, action) => {
  switch (action.type) {
    case 'show modal':
      return {
        ...state,
        showModal: true,
        modal: action.payload.modal,
      }
    case 'close modal':
      return {
        ...state,
        showModal: false,
        modal: undefined,
      }
    default:
      throw new Error('Unknown action for modal reducer')
  }
}

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const reducer = useReducer(modalReducer, {
    showModal: false,
    modal: undefined,
  })

  return (
    <ModalContext.Provider value={reducer}>{children}</ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) throw new Error('Must use ModalProvider to use useModal')

  return context
}

export const showModal = (dispatch, modal) => {
  dispatch({ type: 'show modal', payload: { modal } })
}

export const closeModal = (dispatch) => {
  dispatch({ type: 'close modal' })
}
