import { useMutation } from 'react-query'
import emailjs from '@emailjs/browser'
import { submitTFAForm } from '../api'
import { useNavigate } from 'react-router-dom'
import { useFormError, showError } from './useFormError'

const serviceId = 'service_sz81qhj'
const templateId = 'template_vgy4cig'
const publicKey = 'YKzkepX8MX0vftxu9'

export const useSubmitTFAFormMutation = () => {
  const navigate = useNavigate()
  const [, setError] = useFormError()
  const { mutate, isLoading } = useMutation((data) => submitTFAForm(data), {
    onSuccess: async ({ surveyResponse, result, fullName, email }) => {
      const templateParams = {
        survey_response: surveyResponse,
        from_name: fullName,
        case_email: email,
      }

      try {
        await emailjs.send(serviceId, templateId, templateParams, publicKey)
      } catch (error) {
        console.error(error)

        if (error.response) {
          console.error(error.response.body)
        }
      }
      navigate('/results', {
        state: {
          resultPercentage: result,
        },
      })
    },
    onError: (err) => {
      const response = err.response
      showError(
        setError,
        response?.data?.error?.message || err.message || 'Unknown error',
      )
    },
  })
  return { isLoading, onSubmit: mutate }
}
