import { TFAForm } from './TFAForm'
import Card from 'react-bootstrap/Card'
import { Routes, Route } from 'react-router-dom'
import brandImg from '../brand.jpeg'
import { ResultPage } from './ResultPage'
import { FormWrapper } from './FormWrapper'

export const ClientPage = () => {
  return (
    <FormWrapper
      title="TFA Form"
      subTitle="Developed in partnership with GenWest and G-SEC"
      titleIsLarge
    >
      <Routes>
        <Route path="/" element={<TFAForm />} />
        <Route path="/results" element={<ResultPage />} />
      </Routes>
      <Card.Img variant="bottom" src={brandImg} />
    </FormWrapper>
  )
}
