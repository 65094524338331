import { useState, useContext, createContext } from 'react'

const FormErrorContext = createContext()

export const FormErrorContextProvider = ({ children }) => {
  const state = useState('')

  return (
    <FormErrorContext.Provider value={state}>
      {children}
    </FormErrorContext.Provider>
  )
}

export const useFormError = () => {
  const context = useContext(FormErrorContext)
  if (!context)
    throw new Error('Must use FormErrorContextProvider to use useFormError')

  return context
}

export const showError = (setError, msg) => {
  setError(msg)
}

export const removeError = (setError) => {
  setError('')
}
