import { useMutation } from 'react-query'
import { deleteUser } from '../api'
import { useModal, closeModal } from './useModal'
import { useFormError, showError } from './useFormError'

export const useDeleteUserMutation = ({ isModal }) => {
  const [, dispatch] = useModal()
  const [, setError] = useFormError()
  const { mutate, isLoading } = useMutation(
    ({ userName }) => deleteUser({ userName }),
    {
      onSuccess: () => {
        if (isModal) closeModal(dispatch)
      },
      onError: (err) => {
        const data = err.response
        showError(setError, data.error.message || data)
      },
    },
  )
  return { isLoading, onSubmit: mutate }
}
