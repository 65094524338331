import { useQuery } from 'react-query'
import { getClients } from '../api'

export const useGetClients = () => {
  const { data, isLoading, isRefetching, isError, isSuccess } = useQuery(
    'get-clients',
    () => getClients(),
  )
  return { isLoading, isRefetching, data, isError, isSuccess }
}
