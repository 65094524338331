import { useMutation } from 'react-query'
import { login } from '../api'
import { useTFAToken } from './useTFAToken'
import { useNavigate } from 'react-router-dom'
import { useFormError, showError } from './useFormError'

export const useLoginMutation = () => {
  const [, setTFAToken] = useTFAToken()
  const [, setError] = useFormError()
  const navigate = useNavigate()
  const { mutate, isLoading } = useMutation(
    ({ userName, password }) => {
      return login({ userName, password })
    },
    {
      onSuccess: (data) => {
        setTFAToken(data)
        navigate('/')
      },
      onError: (err) => {
        const response = err.response
        showError(
          setError,
          response?.data?.error?.message || err.message || 'Unknown error',
        )
      },
    },
  )
  return { isLoading, onSubmit: mutate }
}
