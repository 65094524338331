import axios from 'axios'
import { tfaTokenKey } from '../config'

let instance = axios.create({
  responseType: 'json',
})

instance.interceptors.request.use((config) => {
  // Setting setting session token for web api calls
  let tokenObj = sessionStorage.getItem(tfaTokenKey)
  if (tokenObj) {
    const { token } = JSON.parse(tokenObj)
    config.headers[tfaTokenKey] = `Bearer ${token}`
  }
  return config
})

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) sessionStorage.setItem(tfaTokenKey, '')
    return Promise.reject(err)
  },
)

export default instance
