import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { useGetClients } from '../hooks'
import { AllClientsTable } from './AllClientsTable'

export const AdminPage = () => {
  const { data, isSuccess, isLoading } = useGetClients()

  if (isLoading) {
    return (
      <div className="center-div">
        <Spinner animation="border" />
      </div>
    )
  }
  if (isSuccess) {
    const { clients } = data
    return <AllClientsTable clients={clients} />
  }

  return <Alert variant="warning">Error fetching clients</Alert>
}
