import ProgressBar from 'react-bootstrap/ProgressBar'
import Card from 'react-bootstrap/Card'
import Alert from 'react-bootstrap/Alert'
import { useLocation } from 'react-router-dom'

export const ResultPage = () => {
  const location = useLocation()
  const result = location.state.resultPercentage
  let variant = 'success'
  let message = (
    <p>
      The assessment is low risk, and client could benefit from general
      technical safety advice. Please see below links to improve the device’s
      safety and integrity.
    </p>
  )
  if (result > 25 && result <= 52.6) {
    message = (
      <p>
        The assessment is moderate risk. A professional analysis may be
        necessary. Client could also benefit from general technical safety
        advice. Please see below links to improve the device’s safety and
        integrity.
      </p>
    )
    variant = 'warning'
  } else if (result > 52.6) {
    message = (
      <p>
        The assessment is high risk. A professional analysis should be conducted
        as soon as possible. Client will also benefit from observing general
        technical security advice. Please see below links.
      </p>
    )
    variant = 'danger'
  }
  return (
    <>
      <Card.Title>Results of your submission</Card.Title>
      <Card.Text>
        <Alert variant={variant}>
          {message}
          <p>
            <a href="https://techsafety.org.au/resources/resources-women/12tips4smartphones/">
              https://techsafety.org.au/resources/resources-women/12tips4smartphones/
            </a>
          </p>
          <p>
            <a href="https://www.cyber.gov.au/acsc/individuals-and-families/protect-your-devices">
              https://www.cyber.gov.au/acsc/individuals-and-families/protect-your-devices
            </a>
          </p>
          <p>
            {
              'If you require further assistance from the Personal Safety Initiative please contact your local PSI coordinator from '
            }
            <a href="https://gsec-form-app-statics.s3.ap-southeast-2.amazonaws.com/PSI+coordinators+contact+list+01.03.22+(003).pdf">
              here
            </a>
            .
          </p>
        </Alert>
        <ProgressBar variant={variant} now={result} />
      </Card.Text>
    </>
  )
}
