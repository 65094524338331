import { useState, useContext, createContext, useEffect, useRef } from 'react'
import { tfaTokenKey } from '../config'

const TFATokenContext = createContext()

export const TFATokenProvider = ({ children }) => {
  const token = sessionStorage.getItem(tfaTokenKey)
  const [tfaToken, setTFAToken] = useState(token ? JSON.parse(token) : null)

  const beforeUnloadRef = useRef()
  beforeUnloadRef.current = () => {
    if (tfaToken) sessionStorage.setItem(tfaTokenKey, JSON.stringify(tfaToken))
    else sessionStorage.setItem(tfaTokenKey, '')
  }

  useEffect(() => {
    beforeUnloadRef.current()
  }, [tfaToken])

  useEffect(() => {
    const beforeUnload = () => beforeUnloadRef.current()

    window.addEventListener('beforeunload', beforeUnload)

    return () => {
      beforeUnload()

      window.removeEventListener('beforeunload', beforeUnload)
    }
  })

  return (
    <TFATokenContext.Provider value={[tfaToken, setTFAToken]}>
      {children}
    </TFATokenContext.Provider>
  )
}

export const useTFAToken = () => {
  const context = useContext(TFATokenContext)
  if (!context) throw new Error('Must use TFATokenProvider to use useTFAToken')

  return context
}
