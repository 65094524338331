import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { TextField } from './TextField'
import Spinner from 'react-bootstrap/Spinner'
import { FormWrapper } from './FormWrapper'
import { useLoginMutation } from '../hooks'

const schema = yup.object({
  userName: yup.string().required('Please enter your user name'),
  password: yup.string().required('Please enter your password'),
})

const LoginForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      userName: '',
      password: '',
    },
  })

  const { onSubmit, isLoading } = useLoginMutation()

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="userName"
        label="User name"
        type="text"
        control={control}
        error={errors.userName?.message}
      />
      <TextField
        name="password"
        label="Password"
        type="password"
        control={control}
        error={errors.password?.message}
      />
      <hr />
      <div className="right-align">
        <Button type="submit" variant="primary">
          {isLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" />
          )}
          Log in
        </Button>
      </div>
    </Form>
  )
}

export const Login = () => (
  <FormWrapper title="Log in">
    <LoginForm />
  </FormWrapper>
)
