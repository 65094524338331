import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { TextField } from './TextField'
import Spinner from 'react-bootstrap/Spinner'
import { FormWrapper } from './FormWrapper'
import { useUpdateUserMutation, useGetUser } from '../hooks'
import Alert from 'react-bootstrap/Alert'

const schema = yup.object({
  userName: yup.string(),
  password: yup.string(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match"),
  email: yup.string().required('Please enter your email'),
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
})

export const UpdateUser = ({ userName, isModal, onClose }) => {
  const { isLoading, isRefetching, isSuccess, data } = useGetUser({ userName })

  if (isLoading || isRefetching) {
    return (
      <div className="center-div">
        <Spinner animation="border" />
      </div>
    )
  }

  if (isSuccess) {
    const { user } = data
    return (
      <FormWrapper title="Update user">
        <UpdateUserForm user={user} isModal onClose={onClose} />
      </FormWrapper>
    )
  }

  return <Alert variant="warning">Error fetching user details</Alert>
}

const UpdateUserForm = ({ user, isModal, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      userName: user.userName,
      password: '',
      confirmPassword: '',
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    },
  })

  const { onSubmit, isLoading } = useUpdateUserMutation({ isModal })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="userName"
        label="User name"
        type="text"
        control={control}
        disabled
      />
      <TextField
        name="email"
        label="E-mail"
        type="email"
        control={control}
        error={errors.email?.message}
      />
      <TextField
        name="firstName"
        label="First name"
        type="text"
        control={control}
        error={errors.firstName?.message}
      />
      <TextField
        name="lastName"
        label="Last name"
        type="text"
        control={control}
        error={errors.lastName?.message}
      />
      <TextField
        name="password"
        label="Password"
        type="password"
        control={control}
        error={errors.password?.message}
      />
      <TextField
        name="confirmPassword"
        label="Confirm password"
        type="password"
        control={control}
        error={errors.confirmPassword?.message}
      />
      <hr />
      <div className="right-align">
        {isModal && (
          <Button variant="danger" onClick={onClose} className="margin-right">
            Close
          </Button>
        )}
        <Button type="submit" variant="primary">
          {isLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" />
          )}
          Update
        </Button>
      </div>
    </Form>
  )
}
