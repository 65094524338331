import Form from 'react-bootstrap/Form'
import { Controller } from 'react-hook-form'
import './InputField.css'

export const CheckField = ({ label, name, control, type, error, disabled }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { ref: _ref, ...field } }) => (
      <Form.Group className="mb-3">
        <Form.Check
          label={label}
          disabled={disabled}
          type={type}
          name={name}
          {...field}
        ></Form.Check>
        <Form.Text className="formTextRed" variant="error">
          {error}
        </Form.Text>
      </Form.Group>
    )}
  />
)
