import fetch from '../util/fetch'
import { backendUrl } from '../config'

export const login = ({ userName, password }) =>
  fetch({
    url: `${backendUrl}/login`,
    method: 'POST',
    data: {
      userName,
      password,
    },
  }).then((res) => res.data)
